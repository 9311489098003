import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  withAuthenticator,
  Greetings,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  ForgotPassword,
  Loading,
} from 'aws-amplify-react';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-sortable-tree/style.css';
import './scss/styles.scss';

import PageViewLogger from './PageViewLogger';
import ApplicationWrapper from './ApplicationWrapper';
import { SignOut } from './auth';
import { Error404, Error500 } from './errorPages';
import './Amplify';

class App extends PureComponent {
  public componentDidMount(): void {
    if (process.env.REACT_APP_SEGMENT) {
      analytics.load(process.env.REACT_APP_SEGMENT);
      analytics.page();
    }
  }

  public render(): React.ReactNode {
    return (
      <>
        <Helmet titleTemplate="%s | Just Pick Something" />
        <Router>
          <PageViewLogger>
            <Switch>
              <Route exact path="/sign-out" name="Sign out" component={SignOut} />
              <Route exact path="/404" name="Error 404" component={Error404} />
              <Route exact path="/500" name="Error 500" component={Error500} />
              <Route path="/" name="Home" component={ApplicationWrapper} />
            </Switch>
          </PageViewLogger>
        </Router>
      </>
    );
  }
}

export default withAuthenticator(App, false, [
  <Greetings />,
  <SignIn />,
  <ConfirmSignIn />,
  <RequireNewPassword />,
  <ForgotPassword />,
  <Loading />,
]);
