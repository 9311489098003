import React from 'react';
import { Row, Col } from 'reactstrap';
import { List } from 'immutable';
import Base, { BaseInputProps } from './Base';
import Dropdown from './Dropdown';

const COUNTRIES = List([
  {
    label: 'United States',
    value: 'United States',
  },
]);

const STATES = List([
  {
    label: 'Illinois',
    value: 'IL',
  },
]);

interface AddressInputsProps extends BaseInputProps {
  showNeighborhood?: boolean;
  showLatLong?: boolean;
}

const AddressInputs: React.FunctionComponent<AddressInputsProps> = ({
  name,
  required,
  showNeighborhood,
  showLatLong,
  ...otherProps
}) => {
  const countryFieldName = `${name}.country`;
  const stateFieldName = `${name}.state`;
  const cityFieldName = `${name}.city`;

  return (
    <Row>
      <Col xs="12">
        <Base {...otherProps} name={`${name}.formattedString`} label="Formatted address" readOnly />
      </Col>
      <Col xs="12">
        <Base
          {...otherProps}
          name={`${name}.streetNumber`}
          label="Street number"
          required={required}
        />
      </Col>
      <Col xs="12">
        <Base {...otherProps} name={`${name}.route`} label="Street name" />
      </Col>
      <Col xs="12">
        <Base {...otherProps} name={`${name}.postalCode`} label="Postal code" required={required} />
      </Col>
      <Col xs="12">
        <Dropdown
          {...otherProps}
          name={countryFieldName}
          label="Country"
          required={required}
          options={COUNTRIES}
        />
      </Col>
      <Col xs="12">
        <Dropdown
          {...otherProps}
          name={stateFieldName}
          label="State"
          required={required}
          options={STATES}
        />
      </Col>
      <Col xs="12">
        <Base {...otherProps} name={cityFieldName} label="City" required={required} />
      </Col>
      {showNeighborhood && (
        <Col xs="12">
          <Base {...otherProps} name={`${name}.neighborhood`} label="Neighborhood" />
        </Col>
      )}
      {showLatLong && (
        <>
          <Col xs="12">
            <Base {...otherProps} name={`${name}.latitude`} label="Latitude" />
          </Col>
          <Col xs="12">
            <Base {...otherProps} name={`${name}.longitude`} label="Longitude" />
          </Col>
        </>
      )}
    </Row>
  );
};

export default AddressInputs;
