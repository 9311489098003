import React from 'react';
import classNames from 'classnames';
import ReactMde from 'react-mde';
import Markdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { ErrorMessage, useField } from 'formik';
import { FormFieldProps } from './types';

const MarkdownEditorInput: React.FC<FormFieldProps> = ({ field }) => {
  const fieldId = field.id ?? field.name;

  const [formikField, meta, helpers] = useField(field);
  const [selectedTab, setSelectedTab] = React.useState('write');

  const classes = classNames({
    'markdown-editor-error--visible': !!meta.error,
  });

  return (
    <FormGroup>
      {field.label && <Label for={fieldId}>{field.label}</Label>}
      <ReactMde
        value={formikField.value}
        onChange={(value: any) => {
          helpers.setValue(value);
          helpers.setTouched(true);
        }}
        selectedTab={selectedTab as any}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={markdown => Promise.resolve(<Markdown source={markdown} />)}
      />
      <ErrorMessage name={field.name} component={FormFeedback} className={classes} />
    </FormGroup>
  );
};

export default MarkdownEditorInput;
