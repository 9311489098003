import { Loadable } from '../../components';
import { DEFINED_LOCATIONS_NAV_PATH } from './constants';

const List = Loadable(() => import('./list'));
const Form = Loadable(() => import('./form'));
const View = Loadable(() => import('./view'));

const routes = [
  { path: DEFINED_LOCATIONS_NAV_PATH, exact: true, name: 'Defined Locations', component: List },
  { path: `${DEFINED_LOCATIONS_NAV_PATH}/list`, exact: true, name: 'List', component: List },
  {
    path: `${DEFINED_LOCATIONS_NAV_PATH}/create`,
    exact: true,
    name: 'Create defined location',
    component: Form,
  },
  { path: `${DEFINED_LOCATIONS_NAV_PATH}/:id`, exact: true, name: 'Details', component: View },
  {
    path: `${DEFINED_LOCATIONS_NAV_PATH}/:id/edit`,
    exact: true,
    name: 'Edit defined location',
    component: Form,
  },
];

export default routes;
