import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import * as Yup from 'yup';
import { FormikForm } from '../formikForm';

const SINGLE_MB = 1000000;
const MAX_FILE_SIZE = 10 * SINGLE_MB; // 10 Mb;
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

const formSchema = Yup.object().shape({
  url: Yup.string(),
  file: Yup.mixed()
    .test('fileSize', `File is over ${MAX_FILE_SIZE/SINGLE_MB} MB`, value => value ? value.size <= MAX_FILE_SIZE : true)
    .test('fileType', 'File must be a valid image', value => value ? SUPPORTED_FORMATS.includes(value.type) : true),
});

const fields = [
  {
    label: 'Media file',
    name: 'file',
    type: 'file',
  },
  {
    label: 'Media Url',
    name: 'url',
    type: 'url',
  },
];

interface LabelFormModalProps {
  onToggle: () => void;
  onSave: (values: any, actions: any) => void;
  media?: any;
  isOpen?: boolean;
  hasError?: boolean;
}

const MediaFormModal: React.FunctionComponent<LabelFormModalProps> = ({
  isOpen,
  onToggle,
  onSave,
}) => {
  function handleSave(values: any, actions: any) {
    const requiredFieldDefined = values.url || values.file;

    if (requiredFieldDefined) {
      onSave(values, actions);
    }

    actions.setSubmitting(false);
  }

  function renderForm(): React.ReactNode {
    return (
      <ModalBody>
        <FormikForm
          existingValues={{
            url: '',
            file: '',
          }}
          schema={formSchema}
          onSubmit={handleSave}
          fields={fields}
          submitButtonText="Save"
        />
      </ModalBody>
    );
  }

  const header = 'Create media';
  const content = renderForm();

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{header}</ModalHeader>
      {content}
    </Modal>
  );
};

export default MediaFormModal;
