import { List } from 'immutable';
import { ISystemAlert } from '../../models';
import { BaseAction } from '../types';

// Action Types
const REDUX_PREFIX = 'ALERTS';

export const ADD_ALERT = `${REDUX_PREFIX}/ADD_ALERT`;
export const DISMISS_ALERT = `${REDUX_PREFIX}/DISMISS_ALERT`;

// Actions
export interface AddAlertAction extends BaseAction {
  type: typeof ADD_ALERT;
  payload: ISystemAlert;
}

export interface DismissAlertAction extends BaseAction {
  type: typeof DISMISS_ALERT;
  payload: number;
}

export type SystemAlertActionTypes = AddAlertAction | DismissAlertAction;

// State
export type SystemAlertsMap = List<ISystemAlert>;

export interface SystemAlertState {
  alerts: SystemAlertsMap;
}
