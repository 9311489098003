import React from 'react';
import { Button, Col, Form as RSForm, Row } from 'reactstrap';
import { FormFieldDetails } from './types';
import Input from './Input';
import AddressInputGroup from './AddressInputGroup';
import HiddenInput from './HiddenInput';
import MarkdownEditorInput from './MarkdownEditorInput';
import LinksInput from './LinksInput';
import ConditionsEditor from './ConditionsEditor';
import Select from './Select';
import FileInput from './FileInput';
import AddressLookupWrapper from './AddressLookupWrapper';
import OMDbLookup from './OMDbLookup';

interface FormProps {
  existingValues?: any;
  submitButtonText?: React.ReactNode;
  fields: FormFieldDetails[];
  formikProps: any;
}

const Form: React.FC<FormProps> = ({
  existingValues,
  fields,
  submitButtonText,
  formikProps: { isSubmitting, values, errors, touched, handleSubmit, setFieldValue },
}) => (
  <RSForm translate={null as any} onSubmit={handleSubmit}>
    {existingValues?.id && <HiddenInput field={{ name: 'id' }} />}
    {fields.map(field => {
      let InputType = Input;
      let extraProps = field.extraProps ?? {};

      if (field.hideOnUpdate && existingValues?.id) {
        return null;
      }

      switch (field.type) {
        case 'file':
          InputType = FileInput;
          break;
        case 'address':
          InputType = AddressInputGroup;
          break;
        case 'addressLookup':
          InputType = AddressLookupWrapper;
          break;
        case 'omdbLookup':
          InputType = OMDbLookup;
          break;
        case 'hidden':
          InputType = HiddenInput;
          break;
        case 'markdown':
          InputType = MarkdownEditorInput;
          break;
        case 'links':
          InputType = LinksInput as any;
          break;
        case 'select':
        case 'multiselect':
          InputType = Select;
          break;
        case 'conditions':
          InputType = ConditionsEditor as any;
          extraProps = { labels: existingValues?.labels };
          break;
        default:
          break;
      }

      return (
        <InputType
          key={field.name}
          field={field}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          {...extraProps}
        />
      );
    })}
    {submitButtonText && (
      <Row>
        <Col className="text-right">
          <Button type="submit" color="primary" disabled={isSubmitting}>
            {submitButtonText}
          </Button>
        </Col>
      </Row>
    )}
  </RSForm>
);

export default Form;
