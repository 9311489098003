import React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Alert, Table } from 'reactstrap';
import { formatCurrency } from '../../utilities';

interface LinksListProps {
  links: any[];
}

const LinksList: React.FC<LinksListProps> = ({ links }) => {
  function renderContent(): React.ReactNode {
    if (!links || links.length === 0) {
      return (
        <tr>
          <td colSpan={4}>
            <Alert color="secondary" className="mt-3 text-center w-100">
              No links found
            </Alert>
          </td>
        </tr>
      );
    }

    return links.map((link: any) => (
      <tr key={link.url}>
        <td>
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            {link.text || link.url}
          </a>
        </td>
        <td>{link.provider}</td>
        <td>{formatCurrency(link.cost)}</td>
        <td>{link.platform}</td>
      </tr>
    ));
  }

  return (
    <Table striped>
      <thead>
        <tr>
          <th>URL</th>
          <th>Provider</th>
          <th>Cost</th>
          <th>Platform</th>
        </tr>
      </thead>
      <tbody>{renderContent()}</tbody>
    </Table>
  );
};

export default createFragmentContainer(LinksList, {
  links: graphql`
    fragment LinksList_links on Link @relay(plural: true) {
      text
      url
      platform
      provider
      cost
    }
  `,
});
