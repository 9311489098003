import React from 'react';
import { Container } from 'reactstrap';
import { AppAside, AppBreadcrumb, AppFooter, AppHeader } from '@coreui/react';
import Aside from './Aside';
import Footer from './Footer';
import Header from './Header';
import Navigation from './Navigation';
import Routes from './Routes';
import Alerts from './Alerts.connected';

interface LayoutProps {
  routes: any[];
}

const Layout: React.FunctionComponent<LayoutProps> = ({ routes, ...otherProps }) => (
  <div className="app">
    <AppHeader fixed>
      <Header />
    </AppHeader>
    <div className="app-body">
      <Navigation {...otherProps} />
      <main className="main">
        <AppBreadcrumb appRoutes={routes} />
        <Alerts />
        <Container fluid>
          <Routes routes={routes} />
        </Container>
      </main>
      <AppAside fixed hidden>
        <Aside />
      </AppAside>
    </div>
    <AppFooter>
      <Footer />
    </AppFooter>
  </div>
);

export default Layout;
