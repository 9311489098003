import { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import env from '../../env';

export const useGoogleAutocomplete = (input: string): [boolean, any[]] => {
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const service = new google.maps.places.AutocompleteService();

  useEffect(() => {
    if (!sessionToken) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken());
    }
  }, [sessionToken]);

  useEffect(() => {
    async function fetchGoogleAutocomplete(): Promise<void> {
      let mappedSuggestions: any[];

      service.getPlacePredictions(
        { input, sessionToken },
        (
          predictions: google.maps.places.QueryAutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
            console.error(status);
            mappedSuggestions = [];
          } else {
            mappedSuggestions = predictions;
          }

          setSuggestions(mappedSuggestions);
          setLoading(false);
        },
      );
    }

    if (!loading && input && sessionToken) {
      setLoading(true);
      fetchGoogleAutocomplete();
    }
  }, [input, sessionToken]);

  return [loading, suggestions];
};

export const useGooglePlaceDetails = (placeId: string): [boolean, any] => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const service = new google.maps.places.PlacesService(document.createElement('div'));

  useEffect(() => {
    async function fetchGooglePlaceDetails(): Promise<void> {
      let mappedDetails = {};

      service.getDetails(
        {
          placeId,
          fields: ['address_component', 'geometry', 'business_status'],
        },
        (responseDetails, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK || !responseDetails) {
            console.error(status);
          } else {
            const combinedDetails: { [key: string]: any } = {};

            responseDetails.address_components?.map(addressComponent => {
              const key = addressComponent.types[0];
              combinedDetails[key] = addressComponent.short_name;
            });

            mappedDetails = {
              street1: `${combinedDetails.street_number} ${combinedDetails.route}`,
              street2: combinedDetails.floor,
              city: combinedDetails.locality,
              state: combinedDetails.administrative_area_level_1,
              country: combinedDetails.country,
              postalCode: combinedDetails.postal_code,
              neighborhood: combinedDetails.neighborhood,
              latitude: responseDetails.geometry?.location?.lat(),
              longitude: responseDetails.geometry?.location?.lng(),
              utcOffset: responseDetails.utc_offset_minutes,
            };
          }

          setDetails(mappedDetails);
          setLoading(false);
        },
      );
    }

    if (!loading && placeId) {
      setLoading(true);
      fetchGooglePlaceDetails();
    }
  }, [placeId]);

  return [loading, details];
};

interface TimezoneDbResponse {
  abbreviation: string;
  countryCode: string;
  countryName: string;
  dst: string | number;
  formatted: string;
  gmtOffset: number;
  message: string;
  nextAbbreviation: string;
  status: string;
  timestamp: number;
  zoneEnd: number;
  zoneName: string;
  zoneStart: number;
}

export const useTimezoneDb = (formFieldValues: { [key: string]: any } = {}): [boolean, TimezoneDbResponse | undefined] => {
  const { latitude, longitude, timezoneName } = formFieldValues;
  const [loading, setLoading] = useState(false);
  const [timezoneDetails, setTimezoneDetails] = useState<TimezoneDbResponse>();

  useEffect(() => {
    async function fetchTimezoneDetails(): Promise<void> {
      const timezoneResponse = await axios.get<TimezoneDbResponse>(`https://api.timezonedb.com/v2.1/get-time-zone?key=${env.getTimezoneDbApiKey()}&format=json&by=position&lat=${latitude}&lng=${longitude}`)

      setTimezoneDetails(timezoneResponse.data);
      setLoading(false);
    }

    if (!loading && !timezoneName && latitude && longitude) {
      setLoading(true);
      fetchTimezoneDetails();
    }
  }, [timezoneName, latitude, longitude]);

  return [loading, timezoneDetails];
};

enum OMDbLookupType {
  MOVIE = 'movie',
  SERIES = 'series',
  EPISODE = 'episode',
}

export interface OMDbLookupOptions {
  title?: string;
  imdbId?: string;
  type?: OMDbLookupType;
  year?: string;
}

export const useOMDbLookup = (options: OMDbLookupOptions): any => {
  const [loading, setLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const { title, imdbId, type, year } = options;

  useEffect(() => {
    async function fetchOMDbInfo(): Promise<void> {
      const rawParams: { [key: string]: string } = {};

      if (title) {
        rawParams.t = title;
      }

      if (imdbId) {
        rawParams.i = imdbId;
      }

      if (year) {
        rawParams.y = year;
      }

      if (type) {
        rawParams.type = type;
      }

      const params = new URLSearchParams(rawParams as any).toString();

      const response = await fetch(
        `https://www.omdbapi.com/?${params}&apiKey=${env.getOMDbApiKey()}`,
      ).then(res => res.json());

      setDetails(response);
      setLoading(false);
    }

    const hasValidSearchTerms = (title && title.length > 2) || (imdbId && imdbId.length > 8);

    if (!loading && hasValidSearchTerms) {
      setLoading(true);
      fetchOMDbInfo();
    }
  }, [title, imdbId, type, year]);

  return [loading, details];
};
