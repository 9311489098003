import React from 'react';
import classNames from 'classnames';
import graphql from 'babel-plugin-relay/macro';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { QueryRenderer, commitMutation } from 'react-relay';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Button,
  FormGroup,
  Label,
  FormFeedback,
} from 'reactstrap';
import * as Yup from 'yup';
import relayEnvironment from '../../Relay';
import { Spinner } from '..';

const existingLabelQuery = graphql`
  query LabelFormModalQuery($id: ID!) {
    getLabel(id: $id) {
      node {
        id
        name
        description
        parentId
      }
    }
  }
`;

const createLabelMutation = graphql`
  mutation LabelFormModalCreateMutation($input: CreateLabelInput!) {
    createLabel(input: $input) {
      node {
        id
        name
        description
      }
    }
  }
`;

const updateLabelMutation = graphql`
  mutation LabelFormModalUpdateMutation($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      node {
        id
        name
        description
        parentId
      }
    }
  }
`;

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'The name of the establishment must be more than 2 characters')
    .required('A name is required'),
});

interface LabelFormModalProps {
  onToggle: () => void;
  label?: any;
  isOpen?: boolean;
}

const LabelFormModal: React.FunctionComponent<LabelFormModalProps> = ({
  isOpen,
  onToggle,
  label,
}) => {
  function onSubmit(values: object, actions: any): void {
    const mutation = label?.id ? updateLabelMutation : createLabelMutation;

    commitMutation(relayEnvironment, {
      mutation,
      variables: { input: values },
      onCompleted: (response: any, errors: any) => {
        if (!errors) {
          onToggle();
        } else {
          actions.setStatus('error');
        }

        actions.setSubmitting(false);
      },
      onError: () => {
        actions.setStatus('error');
        actions.setSubmitting(false);
      },
    });
  }

  function renderForm(existingValues?: any): React.ReactNode {
    return (
      <Formik
        initialValues={{
          id: existingValues?.id,
          name: existingValues?.name ?? '',
          description: existingValues?.description ?? '',
        }}
        validationSchema={formSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, errors, touched, status, handleSubmit }) => (
          <Form translate={null as any} onSubmit={handleSubmit}>
            <ModalBody>
              {status === 'error' && (
                <Alert color="danger">Oops, there was an issue saving this label</Alert>
              )}

              <Field id="id" type="hidden" name="id" />
              <FormGroup>
                <Label for="name">Name</Label>
                <Field
                  id="name"
                  type="text"
                  name="name"
                  className={classNames('form-control', {
                    'is-invalid': !!errors?.name && touched?.name,
                  })}
                />
                <ErrorMessage name="name" component={FormFeedback} />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Field id="description" name="description" as="textarea" className="form-control" />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    );
  }

  function renderExistingLabel(): React.ReactNode {
    return (
      <QueryRenderer
        environment={relayEnvironment}
        query={existingLabelQuery}
        variables={{ id: label?.id }}
        render={({ error, props }: any) => {
          if (error) {
            return (
              <Alert color="danger" className="mt-3">
                Oops, there was an issue fetching the label details
              </Alert>
            );
          }

          if (!props) {
            return <Spinner />;
          }

          return renderForm(props?.getLabel?.node);
        }}
      />
    );
  }

  const header = label?.id ? 'Edit label' : 'Create label';
  const content = label?.id ? renderExistingLabel() : renderForm();

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>{header}</ModalHeader>
      {content}
    </Modal>
  );
};

export default LabelFormModal;
