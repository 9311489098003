import { each } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function bindActions(actions: { [key: string]: Function } = {}, dispatch: any) {
  const actionsBound: any = {};

  each(actions, (action: Function, actionKey: string) => {
    actionsBound[actionKey] = bindActionCreators(action as any, dispatch);
  });

  return actionsBound;
}

function connectComponent(component: any, state: any = () => ({}), actions?: any) {
  return withRouter(connect(
    state,
    bindActions.bind({}, actions),
  )(component) as any);
}

export default connectComponent;
