import ReactLoadable from 'react-loadable';
import PageLoading from '../pageLoading';

const Loadable = (component: any, opts = {}) =>
  ReactLoadable({
    loader: component,
    loading: PageLoading,
    ...opts,
  });

export default Loadable;
