import React from 'react';
import classNames from 'classnames';
import { Field, ErrorMessage, getIn } from 'formik';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { FormFieldProps, FormFieldDetails, SelectFieldOption } from './types';
import { format, utcToZonedTime } from 'date-fns-tz';

interface SelectFieldOptionsProps {
  field: FormFieldDetails;
}

const SelectFieldOptions: React.FC<SelectFieldOptionsProps> = ({ field }) => (
  <>
    <option>Select</option>
    {field?.selectOptions?.map((option: SelectFieldOption) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </>
);

const Input: React.FC<FormFieldProps> = ({ field, values, children, errors, touched }) => {
  const fieldId = field.id ?? field.name;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  let value = getIn(values, field.name) ?? '';
  let content = children;

  if (field.as === 'select' && field.selectOptions) {
    content = content || <SelectFieldOptions field={field} />;
  } else if (field.type === 'date') {
    const timezone = values?.timezoneName ?? 'UTC';
    value = value ? format(utcToZonedTime(value, timezone), 'yyyy-MM-dd') : '';
  } else if (field.type === 'datetime-local') {
    const timezone = values?.timezoneName ?? 'UTC';
    value = value ? format(utcToZonedTime(value, timezone), "yyyy-MM-dd'T'HH:mm:ss.SSS") : '';
  }

  return (
    <FormGroup>
      {field.label && <Label for={fieldId}>{field.label}</Label>}
      <Field
        id={fieldId}
        as={field.as}
        type={field.type ?? 'text'}
        name={field.name}
        placeholder={field.placeholder}
        className={classNames('form-control', {
          'is-invalid': !!error && touch,
        })}
        value={value}
      >
        {content}
      </Field>
      <ErrorMessage name={fieldId} component={FormFeedback} />
    </FormGroup>
  );
};

export default Input;
