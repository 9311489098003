import { Loadable } from '../../components';

const LabelsList = Loadable(() => import('./list'));

const routes = [
  { path: '/labels', exact: true, name: 'Labels', component: LabelsList },
  { path: '/labels/list', exact: true, name: 'List', component: LabelsList },
];

export default routes;
