import React, { PureComponent } from 'react';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import { ISystemAlert } from '../../models';

interface AlertProps {
  alert: ISystemAlert;
  onDismiss?: () => void;
}

interface AlertState {
  dismissTimeout?: number;
}

class Alert extends PureComponent<AlertProps, AlertState> {
  constructor(props: AlertProps) {
    super(props);

    this.state = {
      dismissTimeout: undefined,
    };
  }

  public componentDidMount(): void {
    const { alert, onDismiss } = this.props;

    if (alert.isDismissable() && alert.getDismissTime()) {
      this.setState({
        dismissTimeout: setTimeout(onDismiss as any, alert.getDismissTime()),
      });
    }
  }

  public componentWillUnmount(): void {
    const { dismissTimeout } = this.state;

    if (dismissTimeout) {
      clearTimeout(dismissTimeout);
    }
  }

  public render(): React.ReactNode {
    const { alert, onDismiss } = this.props;

    return (
      <Toast
        color={alert.getLevel()}
        isOpen={alert.isDismissable() ? true : undefined}
        onClick={onDismiss}
      >
        <ToastHeader icon={alert.getLevel()}>{alert.getHeader()}</ToastHeader>
        <ToastBody>{alert.getMessage()}</ToastBody>
      </Toast>
    );
  }
}

export default Alert;
