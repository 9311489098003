import { List } from 'immutable';
import { ISystemAlert } from '../../models';
import { SystemAlertState, SystemAlertActionTypes, ADD_ALERT, DISMISS_ALERT } from './types';

const initialState: SystemAlertState = {
  alerts: List<ISystemAlert>(),
};

export default function systemAlertsReducer(state = initialState, action: SystemAlertActionTypes) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        alerts: state.alerts.push(action.payload as ISystemAlert),
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alerts: state.alerts.delete(action.payload as number),
      };
    default:
      return state;
  }
}
