import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { Alert } from 'reactstrap';
import { Query, Builder, Utils as QbUtils } from 'react-awesome-query-builder';
import 'antd/dist/antd.css';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { QueryRenderer } from 'react-relay';
import { formConditionsFieldConfig } from '../../constants';
import relayEnvironment from '../../Relay';
import { FormFieldProps } from './types';
import Spinner from '../spinner';

const query = graphql`
  query ConditionsEditorQuery($filters: GetLabelsFiltersInput) {
    getLabels(filters: $filters) {
      edges {
        node {
          id
          name
          parentId
        }
      }
    }
  }
`;

const DEFAULT_QUERY_VALUE = { id: QbUtils.uuid(), type: 'group' } as any;

class ConditionsEditor extends React.Component<FormFieldProps, any> {
  constructor(props: any) {
    super(props);

    const fieldValue = props.values[props.field.name];
    const queryValue = fieldValue ? JSON.parse(fieldValue) : DEFAULT_QUERY_VALUE;

    this.state = {
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), formConditionsFieldConfig),
      config: formConditionsFieldConfig,
    };
  }

  private renderBuilder = (props: any): React.ReactNode => (
    <div className="query-builder-container" style={{ padding: '10px' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  private onChange = (immutableTree: any, config: any): void => {
    const { field, setFieldValue } = this.props;

    this.setState({ tree: immutableTree, config });

    const jsonTree = QbUtils.getTree(immutableTree);

    console.log('jsonTree', jsonTree);

    setFieldValue?.(field.name, JSON.stringify(jsonTree));
  };

  public render = (): React.ReactNode => {
    const { tree, config } = this.state;

    return (
      <QueryRenderer
        environment={relayEnvironment}
        query={query}
        variables={{}}
        render={({ error, props }: any) => {
          if (error) {
            return (
              <Alert color="danger">
                Oops, there was a problem fetching data for conditions editor
              </Alert>
            );
          }

          if (!props) {
            return <Spinner />;
          }

          config.fields.labels.fieldSettings.listValues = props.getLabels.edges.map(
            ({ node: label }: any) => ({
              value: label.id,
              title: label.name,
              parent: label.parentId ?? undefined,
            }),
          );

          return (
            <Query
              {...config}
              value={tree}
              onChange={this.onChange}
              renderBuilder={this.renderBuilder}
            />
          );
        }}
      />
    );
  };
}

export default ConditionsEditor;
