import { Loadable } from '../components';
import { routes as demandRoutes, nav as demandNav } from './demand';
import { routes as competitionRoutes, nav as competitionNav } from './competition';
import { routes as feedbackRoutes, nav as feedbackNav } from './feedback';
import { routes as retroRoutes, nav as retroNav } from './retrospectives';
import { routes as establishmentsRoutes, nav as establishmentsNav } from './establishments';
import { routes as eventsRoutes, nav as eventsNav } from './events';
import { routes as settingsRoutes, nav as settingsNav } from './settings';

const Dashboard = Loadable(() => import('./dashboard'));

export const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  ...demandRoutes,
  ...competitionRoutes,
  ...feedbackRoutes,
  ...retroRoutes,
  ...establishmentsRoutes,
  ...eventsRoutes,
  ...settingsRoutes,
];

export const nav = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-area-chart',
  },
  ...demandNav,
  ...competitionNav,
  ...feedbackNav,
  ...retroNav,
  ...establishmentsNav,
  ...eventsNav,
  ...settingsNav,
];
