import { combineReducers } from 'redux';
import { SystemAlertState } from './alerts/types';
import systemAlertsReducer from './alerts/reducers';

interface AppStateDef {
  alerts: SystemAlertState;
}

const combinedReducers = combineReducers({
  alerts: systemAlertsReducer as any,
});

const rootReducer = (state: any, action: { type: string } = { type: '' }): AppStateDef => {
  return combinedReducers(state, action) as AppStateDef;
};

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
