import React, { PureComponent } from 'react';
import { Map, List } from 'immutable';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Form, { FormFields, FormConfig, FormField } from '../form';
import { Media } from '../../types';

export interface EditModalProps {
  header: React.ReactNode;
  item: any;
  fields: FormFields;
  onSave: (item: any) => void;
  toggle: () => void;
  parent?: any;
  infoText?: React.ReactNode;
  className?: string;
  requiredFields?: List<string>;
  isOpen?: boolean;
  image?: string | Media;
}

interface EditModalState {
  formValues: Map<string, string>;
  formErrors: Map<string, string>;
}

export default class EditModal extends PureComponent<EditModalProps, EditModalState> {
  public constructor(props: EditModalProps) {
    super(props);

    const { item } = props;

    this.state = {
      formValues: item,
      formErrors: Map<string, string>(),
    };
  }

  public componentDidUpdate(prevProps: EditModalProps): void {
    const { item } = this.props;

    if (item !== prevProps.item) {
      this.setState({
        formValues: item,
        formErrors: Map<string, string>(),
      });
    }
  }

  private onFieldChange = (event: React.FormEvent<HTMLInputElement>): void => {
    const { id, value } = (event as any).target as { id: string; value: string };

    this.setState((state: EditModalState) => ({
      formValues: state.formValues.set(id, value),
    }));
  };

  private onSave = (): void => {
    const { onSave, item, parent } = this.props;
    const { formValues } = this.state;

    if (this.validate()) {
      onSave(
        item
          .merge(formValues)
          .set('parent', item.get('parent') || (parent ? parent.get('id') : undefined)),
      );

      this.onToggle();
    }
  };

  private onToggle = (): void => {
    const { toggle } = this.props;

    this.setState({
      formErrors: Map<string, string>(),
      formValues: Map<string, string>(),
    });

    toggle();
  };

  private validate(): boolean {
    const { fields } = this.props;
    const { formValues } = this.state;
    let formErrors = Map<string, string>();

    fields.forEach(field => {
      if (field && field.type !== 'div') {
        if ((field as FormField).required && !formValues.get((field as FormField).name)) {
          formErrors = formErrors.set(
            (field as FormField).name,
            `${(field as FormField).label || 'This field'} is required.`,
          );
        }
      }
    });

    this.setState({ formErrors });

    return formErrors.isEmpty();
  }

  private renderFields(): React.ReactNode {
    const { fields, parent } = this.props;
    const { formValues, formErrors } = this.state;

    return (
      <Form
        config={List([{ fields }]) as FormConfig}
        parent={parent}
        values={formValues}
        errors={formErrors}
        onFieldChange={this.onFieldChange}
        onCancel={this.onToggle}
        onSubmit={this.onSave}
        noContainer
      />
    );
  }

  private renderImage(): React.ReactNode {
    const { image } = this.props;
    let renderedImage = null;

    if (image) {
      const src = typeof image === 'string' ? image : (image as Media).url;

      renderedImage = <img src={src} alt="" className="img-fluid mb-4" />;
    }

    return renderedImage;
  }

  public render(): React.ReactNode {
    const { isOpen, className, header, infoText } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.onToggle} className={className}>
        <ModalHeader toggle={this.onToggle}>{header}</ModalHeader>
        <ModalBody>
          {this.renderImage()}
          {infoText}
          {this.renderFields()}
        </ModalBody>
      </Modal>
    );
  }
}
