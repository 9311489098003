import { ISystemAlert } from '../../models';
import { ADD_ALERT, DISMISS_ALERT, AddAlertAction, DismissAlertAction } from './types';

export type addAlertDef = (alert: ISystemAlert) => AddAlertAction;

export const addAlert: addAlertDef = alert => ({
  type: ADD_ALERT,
  payload: alert,
});

export type dismissAlertDef = (index: number) => DismissAlertAction;

export const dismissAlert: dismissAlertDef = index => ({
  type: DISMISS_ALERT,
  payload: index,
});
