import React from 'react';
import { List } from 'immutable';
import Base, { BaseInputProps } from './Base';

interface DropdownOption {
  label: string;
  value: string;
}

interface DropdownProps extends BaseInputProps {
  options: List<DropdownOption>;
  hideSelect?: boolean;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  options,
  hideSelect,
  ...otherProps
}) => (
  <Base {...otherProps} type="select">
    {!hideSelect && <option value="">Select</option>}
    {options.map((option: any) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </Base>
);

export default Dropdown;
