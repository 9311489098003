/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MediaType = "IMAGE" | "VIDEO" | "%future added value";
export type Gallery_media = ReadonlyArray<{
    readonly url: string;
    readonly type: MediaType | null;
    readonly " $refType": "Gallery_media";
}>;
export type Gallery_media$data = Gallery_media;
export type Gallery_media$key = ReadonlyArray<{
    readonly " $data"?: Gallery_media$data;
    readonly " $fragmentRefs": FragmentRefs<"Gallery_media">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Gallery_media",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Media"
};
(node as any).hash = '78802fbceecb37bc18692ef8c416862c';
export default node;
