import React from 'react';
import Error500 from './Error500';

interface ErrorWrapperState {
  error?: Error;
}

class ErrorWrapper extends React.PureComponent<{}, ErrorWrapperState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  public componentDidCatch(error: Error): void {
    this.setState({
      error,
    });
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { error } = this.state;
    let rendered = children;

    if (error) {
      console.error(error);
      rendered = <Error500 />;
    }

    return rendered;
  }
}

export default ErrorWrapper;
