import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export const SignOut: React.FunctionComponent = () => {
  useEffect(() => {
    (async function signOut(): Promise<void> {
      await Auth.signOut();
    })();
  });

  return <Redirect to="/sign-in" />;
};

export default SignOut;
