import { Loadable } from '../components';
import { routes as uploadRoutes, nav as uploadNav } from './upload';
import { routes as recommendationRoutes, nav as recommendationsNav } from './recommendations';
import { routes as labelsRoutes, nav as labelsNav } from './labels';
import { routes as playlistsRoutes, nav as playlistsNav } from './playlists';
import { routes as discoveryRoutes, nav as discoveryNav } from './discovery';
import { routes as definedLocationsRoutes, nav as definedLoationsNav } from './definedLocations';

const Dashboard = Loadable(() => import('./dashboard'));

export const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  ...uploadRoutes,
  ...discoveryRoutes,
  ...labelsRoutes,
  ...playlistsRoutes,
  ...recommendationRoutes,
  ...definedLocationsRoutes,
];

export const nav = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-area-chart',
  },
  ...uploadNav,
  ...discoveryNav,
  ...labelsNav,
  ...playlistsNav,
  ...definedLoationsNav,
  ...recommendationsNav,
];
