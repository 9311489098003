import React from 'react';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import logo from '../assets/img/brand/logo.png';
import sygnet from '../assets/img/brand/sygnet.png';

const Header: React.FunctionComponent<{}> = () => (
  <>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand
      full={{ src: logo, width: 84, height: 25, alt: 'Just Pick Something' }}
      minimized={{ src: sygnet, width: 23, height: 30, alt: 'Just Pick Something' }}
    />
    <AppSidebarToggler className="d-md-down-none" display="lg" />
    <Nav className="ml-auto" navbar>
      <AppHeaderDropdown>
        <DropdownToggle nav>
          {/* TODO: Update alt={authUser.getName()} */}
          <img className="img-avatar" src="/assets/img/avatars/6.jpg" alt="" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>Account</strong>
          </DropdownItem>
          <DropdownItem>
            <NavItem>
              <NavLink href="/settings">
                <i className="fa fa-wrench" />
                Settings
              </NavLink>
            </NavItem>
          </DropdownItem>
          <DropdownItem>
            <NavItem>
              <NavLink href="/sign-out">
                <i className="fa fa-lock" />
                Sign out
              </NavLink>
            </NavItem>
          </DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    </Nav>
  </>
);

export default Header;
