import React from 'react';
import { Formik } from 'formik';
import { FormFieldDetails } from './types';
import Form from './Form';

interface FormProps {
  existingValues?: { [key: string]: any };
  onSubmit: (formValues: object, actions: any) => void;
  schema: any;
  fields: FormFieldDetails[];
  submitButtonText?: React.ReactNode;
}

const FormikForm: React.FC<FormProps> = ({
  existingValues,
  fields,
  onSubmit,
  schema,
  submitButtonText,
}) => (
  <Formik initialValues={existingValues ?? {}} validationSchema={schema} onSubmit={onSubmit}>
    {(formikProps) => (
      <Form
        existingValues={existingValues}
        fields={fields}
        formikProps={formikProps}
        submitButtonText={submitButtonText}
      />
    )}
  </Formik>
);

export default FormikForm;
