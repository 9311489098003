import React, { PureComponent } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import { Alert, Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import relayEnvironment from '../../Relay';

const deleteLabelMutation = graphql`
  mutation LabelDeleteModalMutation($input: DeleteLabelInput!) {
    deleteLabel(input: $input) {
      deletedId
    }
  }
`;

interface LabelFormModalProps {
  onToggle: () => void;
  label?: any;
  isOpen?: boolean;
}

interface LabelDeleteModalState {
  isSubmitting?: boolean;
  submissionErrored?: boolean;
}

class LabelFormModal extends PureComponent<LabelFormModalProps, LabelDeleteModalState> {
  constructor(props: LabelFormModalProps) {
    super(props);

    this.state = {
      isSubmitting: false,
      submissionErrored: false,
    };
  }

  private onToggle = (): void => {
    const { onToggle } = this.props;

    this.setState(
      {
        isSubmitting: false,
        submissionErrored: false,
      },
      onToggle,
    );
  };

  private onDelete = (): void => {
    const { label } = this.props;

    this.setState({
      isSubmitting: true,
    });

    commitMutation(relayEnvironment, {
      mutation: deleteLabelMutation,
      variables: { input: { id: label.id } },
      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName: 'deletedId',
        },
      ],
      onCompleted: (response: any, errors: any) => {
        if (!errors) {
          this.onToggle();
        } else {
          this.setState({
            submissionErrored: true,
          });
        }
      },
      onError: () => {
        this.setState({
          submissionErrored: true,
        });
      },
    });
  };

  public render(): React.ReactNode {
    const { isOpen, label } = this.props;
    const { isSubmitting, submissionErrored } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={this.onToggle}>
        <ModalHeader toggle={this.onToggle}>Delete?</ModalHeader>
        <ModalBody>
          {submissionErrored && (
            <Alert color="danger">Oops, there was an issue deleting this label</Alert>
          )}
          <p>
            Are you sure you want to delete&nbsp;
            <strong>{label?.name}</strong>?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.onDelete} disabled={isSubmitting}>
            Delete
          </Button>
          <Button color="secondary" onClick={this.onToggle} disabled={isSubmitting}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default LabelFormModal;
