import { Loadable } from '../../components';

const UploadForm = Loadable(() => import('./form'));

export default [
  {
    path: `/upload`,
    exact: true,
    name: 'Upload',
    component: UploadForm,
  },
];
