import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const Error404: React.FC<{}> = () => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
          <div className="clearfix">
            <h1 className="float-left display-3 mr-4">403</h1>
            <h4 className="pt-3">It seems you have stumbled into the wrong field</h4>
            <p className="text-muted float-left">
              We can&apos;t let you stay here, you must leave. If you believe this was a
              problem,&nbsp;
              <a
                href="mailto:help@justpicksomethingapp.com"
                title="Contact Just Pick Something Support"
              >
                please contact support
              </a>
              .
            </p>
          </div>
          <Row>
            <Col xs="12" className="mb-4">
              <Link to="/" title="Go to homepage" className="btn btn-primary">
                Get me out of here
              </Link>
            </Col>
            <Col xs="12">
              <Link to="/sign-out" title="Sign out" className="btn btn-danger">
                Oops, time to sign out
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Error404;
