export enum RecommendationsType {
  ACTIVITY = 'Activity',
  BOOK = 'Book',
  ESTABLISHMENT = 'Establishment',
  EVENT = 'Event',
  GAME = 'Game',
  MOVIE = 'Movie',
  TV = 'TVShow',
}

export interface RecommendationsTypeMap {
  type: RecommendationsType;
  name: string;
  pluralName: string;
  baseUrl: string;
  icon: string;
}

export const RecommendationsTypeMapping: { [key: string]: RecommendationsTypeMap } = {
  [RecommendationsType.ACTIVITY]: {
    type: RecommendationsType.ACTIVITY,
    name: 'Activity',
    pluralName: 'Activities',
    baseUrl: 'activites',
    icon: 'child',
  },
  [RecommendationsType.BOOK]: {
    type: RecommendationsType.BOOK,
    name: 'Book',
    pluralName: 'Books',
    baseUrl: 'books',
    icon: 'book',
  },
  [RecommendationsType.ESTABLISHMENT]: {
    type: RecommendationsType.ESTABLISHMENT,
    name: 'Establishment',
    pluralName: 'Establishments',
    baseUrl: 'establishments',
    icon: 'building',
  },
  [RecommendationsType.EVENT]: {
    type: RecommendationsType.EVENT,
    name: 'Event',
    pluralName: 'Events',
    baseUrl: 'events',
    icon: 'calendar',
  },
  [RecommendationsType.GAME]: {
    type: RecommendationsType.GAME,
    name: 'Game',
    pluralName: 'Games',
    baseUrl: 'games',
    icon: 'gamepad',
  },
  [RecommendationsType.MOVIE]: {
    type: RecommendationsType.MOVIE,
    name: 'Movie',
    pluralName: 'Movies',
    baseUrl: 'movies',
    icon: 'film',
  },
  [RecommendationsType.TV]: {
    type: RecommendationsType.TV,
    name: 'TV Show',
    pluralName: 'TV Shows',
    baseUrl: 'tv-shows',
    icon: 'tv',
  },
} as const;

export const getRecommendationsTypeMappingFromUrl = (urlBaseType: string) =>
  Object.values(RecommendationsTypeMapping).find(
    typeDetails => typeDetails.baseUrl === urlBaseType,
  );
