import { ADD_ALERT } from '../alerts';
import { AlertLevel } from '../../types';

enum API_STATUSES {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

function isPromise(payload: Promise<any> | any) {
  return typeof payload === 'object' && typeof payload.then === 'function';
}

const promiseActionMiddleware = ({ dispatch }: { dispatch: any }) => (next: any) => (
  action: any,
) => {
  if (action && isPromise(action.payload)) {
    const pendingObject: {
      type: string;
      payload?: any;
    } = { type: `${action.type}__${API_STATUSES.PENDING}` };

    if (action.data) {
      pendingObject.payload = action.data;
    }

    dispatch(pendingObject);

    return action.payload
      .then((result: any) =>
        dispatch({
          type: action.type,
          payload: result,
        }),
      )
      .catch((error: any) => {
        console.warn(action.type, error);

        if (action.onFailureMessage) {
          dispatch({
            type: ADD_ALERT,
            payload: {
              level: AlertLevel.DANGER,
              message: action.onFailureMessage,
              dismissable: true,
            },
          });
        }

        return dispatch({
          type: `${action.type}_${API_STATUSES.FAILURE}`,
          payload: error.body || error,
          error: true,
        });
      });
  }

  return next(action);
};

export default promiseActionMiddleware;
