import React from 'react';
import classNames from 'classnames';
import { ErrorMessage, getIn } from 'formik';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { FormFieldProps } from './types';

const FileInput: React.FC<FormFieldProps> = ({
  field,
  values,
  errors,
  touched,
  setFieldValue,
  ...otherProps
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const file = event.currentTarget.files?.[0];

    setFieldValue?.(field.name, file);
  }

  const fieldId = field.id ?? field.name;
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <FormGroup>
      {field.label && <Label for={fieldId}>{field.label}</Label>}
      <input
        id={fieldId}
        type="file"
        name={field.name}
        placeholder={field.placeholder}
        className={classNames('form-control', {
          'is-invalid': !!error && touch,
        })}
        {...otherProps}
        onChange={handleChange}
        value={undefined}
      />
      <ErrorMessage name={fieldId} component={FormFeedback} />
    </FormGroup>
  );
};

export default FileInput;
