import React, { PureComponent } from 'react';
import ReactSortableTree from 'react-sortable-tree';
import { Button } from 'reactstrap';
import graphql from 'babel-plugin-relay/macro';
import { commitMutation } from 'react-relay';
import relayEnvironment from '../../Relay';
import { AlertLevel } from '../../types';
import { SystemAlert } from '../../models';
import LabelFormModal from './LabelFormModal';
import LabelDeleteModal from './LabelDeleteModal';
import { LabelsTreeGlobalProps, Node } from './types';

const LABEL_UPDATE_SUCCESSFUL_MESSAGE = 'Labels updated successfully';
const LABEL_UPDATE_ERROR_MESSAGE = 'Label could not be updated successfully, please try again';

const updateLabelMutation = graphql`
  mutation LabelsTreeUpdateMutation($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      node {
        id
        name
        description
        parentId
      }
    }
  }
`;

interface LabelsTreeProps extends LabelsTreeGlobalProps {
  treeData?: any[];
  searchQuery?: string;
  searchFocusOffset?: number;
  searchFinishCallback?(matches: any[]): void;
  searchMethod?(data: any): boolean;
}

interface LabelsTreeState {
  treeData: any[];
  rawSelectedLabels?: string[];
  editModalObject?: any;
  deleteModalObject?: any;
}

class LabelsTree extends PureComponent<LabelsTreeProps, LabelsTreeState> {
  public static getDerivedStateFromProps(
    props: LabelsTreeProps,
    state: LabelsTreeState,
  ): LabelsTreeState {
    const { selectedLabels } = props;
    const selectedLabelsIds = selectedLabels?.map((label: string | { id: string }) =>
      typeof label === 'object' ? label.id : label,
    );
    const newState = state;

    if (
      selectedLabelsIds &&
      (!state.rawSelectedLabels || selectedLabelsIds !== state.rawSelectedLabels)
    ) {
      newState.rawSelectedLabels = selectedLabelsIds;
    }

    return newState;
  }

  constructor(props: LabelsTreeProps) {
    super(props);

    this.state = {
      treeData: props.treeData ?? [],
      rawSelectedLabels: [],
      editModalObject: undefined,
      deleteModalObject: undefined,
    };
  }

  private closeModals = (): void => {
    this.setState({
      editModalObject: undefined,
      deleteModalObject: undefined,
    });
  };

  private showEditModal = (node: any): void => {
    this.setState({
      editModalObject: node,
      deleteModalObject: undefined,
    });
  };

  private showDeleteModal = (node: any): void => {
    this.setState({
      deleteModalObject: node,
      editModalObject: undefined,
    });
  };

  private onNodeMove = ({ node, nextParentNode }: any): void => {
    const { addAlert } = this.props;

    commitMutation(relayEnvironment, {
      mutation: updateLabelMutation,
      variables: {
        input: {
          id: node.id,
          name: node.name,
          description: node.description,
          parentId: nextParentNode?.id ?? null,
        },
      },
      onCompleted: (response: any, errors: any) => {
        const hasErrors = !!errors;
        const alert = new SystemAlert({
          level: hasErrors ? AlertLevel.DANGER : AlertLevel.SUCCESS,
          message: hasErrors ? LABEL_UPDATE_ERROR_MESSAGE : LABEL_UPDATE_SUCCESSFUL_MESSAGE,
        });

        addAlert?.(alert);
      },
    });
  };

  private onNodeVisibilityToggle = ({ node, expanded }: any): void => {
    console.log('onNodeVisibilityToggle', node, expanded);

    node.expanded = expanded;
  };

  private onTreeChange = (treeData: any): void => {
    this.setState({
      treeData,
    });
  };

  private renderCheckbox(nodeInfo: { node: Node }): React.ReactNode {
    const { onNodeSelect, onUpdate, selectedLabels } = this.props;
    const { rawSelectedLabels } = this.state;
    const { node } = nodeInfo;
    const isSelected = node.selected || rawSelectedLabels?.includes(node.id);

    return (
      <Button
        className="ml-2"
        onClick={() => {
          onNodeSelect?.(node);

          const updatedLabelsList = Object.assign([], selectedLabels);

          if (isSelected) {
            updatedLabelsList?.splice(updatedLabelsList.indexOf(node.id), 1);
          } else {
            updatedLabelsList?.push(node.id);
          }

          onUpdate?.(updatedLabelsList);
        }}
        size="sm"
        outline
        color={isSelected ? 'success' : undefined}
      >
        <i className="fa fa-check" />
      </Button>
    );
  }

  private renderEditButton(nodeInfo: { node: Node }): React.ReactNode {
    return (
      <Button
        className="ml-2"
        onClick={() => this.showEditModal(nodeInfo.node)}
        size="sm"
        outline
        color="info"
      >
        <i className="fa fa-pencil" />
      </Button>
    );
  }

  private renderDeleteButton(nodeInfo: { node: Node }): React.ReactNode {
    return (
      <Button
        className="ml-2"
        onClick={() => this.showDeleteModal(nodeInfo.node)}
        size="sm"
        outline
        color="secondary"
      >
        <i className="fa fa-trash" />
      </Button>
    );
  }

  private renderNodeButtons = (nodeInfo: { node: Node }): { buttons: React.ReactNode[] } => {
    const { canSelect, canEdit, canDelete } = this.props;
    const buttons = [];

    if (canSelect) {
      buttons.push(this.renderCheckbox(nodeInfo));
    }

    if (canDelete) {
      buttons.push(this.renderDeleteButton(nodeInfo));
    }

    if (canEdit) {
      buttons.push(this.renderEditButton(nodeInfo));
    }

    return {
      buttons,
    };
  };

  public render(): React.ReactNode {
    const {
      searchQuery,
      searchFocusOffset,
      searchFinishCallback,
      searchMethod,
      canSort,
    } = this.props;
    const { treeData, editModalObject, deleteModalObject } = this.state;

    return (
      <>
        <div className="sortable-tree__input">
          <ReactSortableTree
            treeData={treeData}
            onChange={this.onTreeChange}
            searchQuery={searchQuery}
            searchFocusOffset={searchFocusOffset}
            searchFinishCallback={searchFinishCallback}
            searchMethod={searchMethod}
            generateNodeProps={this.renderNodeButtons as any}
            onMoveNode={this.onNodeMove}
            canDrag={canSort}
          />
        </div>
        <LabelFormModal
          isOpen={!!editModalObject}
          onToggle={this.closeModals}
          label={editModalObject}
        />
        <LabelDeleteModal
          isOpen={!!deleteModalObject}
          onToggle={this.closeModals}
          label={deleteModalObject}
        />
      </>
    );
  }
}

export default LabelsTree;
