import React from 'react';
import { Map } from 'immutable';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

export interface BaseInputProps {
  name: string;
  type: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  label?: string;
  values?: Map<string, any>;
  errors?: Map<string, any>;
  placeholder?: string;
  className?: string;
  defaultValue?: any;
  parent?: Map<string, any>;
  inherited?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  getValue?: (item: any) => any;
}

const BaseInput: React.FunctionComponent<BaseInputProps> = ({
  name,
  label,
  type,
  values,
  errors,
  inherited,
  parent,
  defaultValue,
  className,
  getValue,
  onChange: onChangeCallback,
  ...otherProps
}) => {
  const fieldPath = name.split('.');
  const error = errors ? errors.getIn(fieldPath) : '';
  const hasError = !!error;
  const isInline = type === 'checkbox';
  let isChecked = false;
  let readOnly = false;
  let onChange = onChangeCallback;

  // eslint-disable-next-line no-nested-ternary
  const currentFieldValue = getValue
    ? getValue(values)
    : values
    ? values.getIn(fieldPath)
    : undefined;

  let value =
    currentFieldValue === undefined || currentFieldValue === null
      ? defaultValue || ''
      : currentFieldValue;

  if (isInline) {
    isChecked = typeof value === 'string' ? value === 'true' : value;

    onChange = () =>
      onChangeCallback &&
      onChangeCallback({
        target: {
          id: name,
          value: !isChecked,
        },
      } as any);
  }

  if (inherited && parent && !parent.isEmpty()) {
    readOnly = true;

    if (!value) {
      value = parent.getIn(fieldPath) || '';

      if (onChange) {
        onChange({
          target: {
            value,
            id: name,
          },
        } as any);
      }
    }
  }

  const renderedLabel = (
    <Label htmlFor={name} check={isInline}>
      {label}
    </Label>
  );

  return (
    <FormGroup check={isInline} className={className}>
      {!isInline && renderedLabel}
      <Input
        id={name}
        type={type as any}
        invalid={hasError}
        value={value}
        checked={isChecked}
        onChange={onChange}
        readOnly={readOnly}
        {...otherProps}
      />
      {isInline && renderedLabel}
      {hasError && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export default BaseInput;
