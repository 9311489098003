import React from 'react';
import classNames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { isMediaToUpload, MediaListItem } from '../../types';

interface DeleteMediaProps {
  media: MediaListItem;
  onDelete: () => void;
  toggle: () => void;
  className?: string;
  isOpen?: boolean;
  hasError?: boolean;
}

const DeleteMedia: React.FunctionComponent<DeleteMediaProps> = ({
  isOpen,
  toggle,
  className,
  onDelete,
  media,
  hasError,
}) => {
  const imageSource = isMediaToUpload(media) ? URL.createObjectURL(media.file) : media.url;

  return (
    <Modal className={classNames('media-gallery-modal', className)} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete media?</ModalHeader>
      <ModalBody>
        {hasError && <Alert color="danger">There was an issue deleting this media</Alert>}
        <img src={imageSource} alt="" />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDelete}>
          Delete
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteMedia;
