import React from 'react';
import Input from './Input';
import { FormFieldProps } from './types';

interface SuggestionMatchedSubstring {
  length: number;
  offset: number;
}

interface Suggestion {
  text: string;
  value: string;
  matchedSubstrings?: SuggestionMatchedSubstring[];
}

interface AutocompleteProps extends FormFieldProps {
  suggestions?: Suggestion[];
  onSuggestionSelect(suggestionValue: string): void;
  onSkip?(): void;
  isSkipped?: boolean;
}

const Autocomplete: React.FC<AutocompleteProps> = ({
  values,
  field,
  suggestions,
  onSuggestionSelect,
  onSkip,
  isSkipped,
  ...otherProps
}) => {
  return (
    <>
      <Input field={field} values={values} {...otherProps} />
      {suggestions && suggestions.length > 0 && (
        <ul>
          {suggestions.map(suggestion => {
            return (
              <li key={suggestion.value}>
                <button type="button" onClick={(): void => onSuggestionSelect(suggestion.value)}>
                  {suggestion.text}
                </button>
              </li>
            );
          })}
        </ul>
      )}
      {onSkip && (
        <label>
          <input type="checkbox" name="skipAutocomplete" value="true" checked={isSkipped} onClick={() => onSkip()} />
          {' Enter address manually'}
        </label>
      )}
    </>
  );
};

export default Autocomplete;
