import { ErrorMessage, useField } from 'formik';
import React from 'react';
import ReactSelect from 'react-select';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { FormFieldProps, SelectFieldOption } from './types';

function convertToReactSelectValue(
  value: string | string[],
  options: SelectFieldOption[] | undefined,
): (SelectFieldOption | undefined)[] {
  const arrayValue = Array.isArray(value) ? value : [value];
  const convertedValue = arrayValue.map((singleValue: string) =>
    options?.find((singleOption: SelectFieldOption) => singleOption.value === singleValue),
  );

  return convertedValue;
}

const Select: React.FC<FormFieldProps> = ({ field }) => {
  const fieldId = field.id ?? field.name;
  const isMultiSelect = field.type === 'multiselect';

  const [formikField] = useField(field);
  const value = convertToReactSelectValue(formikField.value, field.selectOptions);

  return (
    <FormGroup>
      {field.label && <Label for={fieldId}>{field.label}</Label>}
      <ReactSelect
        value={value}
        onChange={(value: any) => {
          formikField.onChange({
            currentTarget: {
              name: field.name,
              value: Array.isArray(value)
                ? value.map((optionValue: SelectFieldOption) => optionValue.value)
                : value?.value,
            },
          });
        }}
        options={field.selectOptions}
        isMulti={isMultiSelect}
        isSearchable
      />
      <ErrorMessage name={fieldId} component={FormFeedback} />
    </FormGroup>
  );
};

export default Select;
