import { createStructuredSelector } from 'reselect';
import { connect } from '../utilities';
import { systemAlertsSelector, dismissAlert } from '../store';
import Alerts from './Alerts';

const selectors = createStructuredSelector({
  alerts: systemAlertsSelector,
});

const actions = {
  dismissAlert,
};

export default connect(
  Alerts,
  selectors,
  actions,
);
