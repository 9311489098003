import React, { useEffect, useState } from 'react';
import { FormFieldProps } from './types';
import { useGoogleAutocomplete, useGooglePlaceDetails } from './hooks';
import Autocomplete from './Autocomplete';
import AddressInputGroup from './AddressInputGroup';

const AddressLookupWrapper: React.FC<FormFieldProps> = ({
  values,
  field,
  setFieldValue,
  ...otherProps
}) => {
  const [skipAutocomplete, setSkipAutocomplete] = useState<boolean>(false);
  const showAutocomplete = !values?.street1;
  const showInputGroup = skipAutocomplete || !showAutocomplete;
  const name = values?.name ?? '';
  const addressInput = values?.[field.name] ?? '';
  const addressSearchInput = addressInput || name || '';
  const [selectedAddress, setSelectedAddress] = useState<string>('');
  const [loadingSuggestions, suggestions] = useGoogleAutocomplete(addressSearchInput);
  const [loadingPlaceDetails, details] = useGooglePlaceDetails(selectedAddress);
  const mappedSuggestions = suggestions?.map(suggestion => ({
    text: suggestion.description,
    value: suggestion.place_id,
    matchedSubstrings: suggestion.matched_substrings,
  }));

  useEffect(() => {
    if (details) {
      Object.entries<any>(details).forEach(([fieldKey, value]) => {
        setFieldValue?.(fieldKey, value);
      });
    }
  }, [details]);

  return (
    <>
      {showAutocomplete && (
        <Autocomplete
          field={{
            ...field,
            label: field.label || 'Address lookup',
            name: field.name || 'addressLookup',
            placeholder: field.placeholder || '123 Main St, City, State, Zipcode',
          }}
          values={values}
          setFieldValue={setFieldValue}
          {...otherProps}
          suggestions={mappedSuggestions}
          onSuggestionSelect={setSelectedAddress}
          onSkip={() => setSkipAutocomplete(!skipAutocomplete)}
          isSkipped={skipAutocomplete}
        />
      )}
      {showInputGroup && (
        <AddressInputGroup
          field={{
            name: 'address',
          }}
          values={values}
          setFieldValue={setFieldValue}
          {...otherProps}
        />
      )}
    </>
  );
};

export default AddressLookupWrapper;
