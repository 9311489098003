import React, { PureComponent } from 'react';
import { Alert } from '../components';
import { SystemAlertsMap, dismissAlertDef } from '../store';

interface AlertsProps {
  alerts: SystemAlertsMap;
  dismissAlert: dismissAlertDef;
}

class Alerts extends PureComponent<AlertsProps> {
  private onDismiss = (index: number) => {
    const { dismissAlert } = this.props;

    dismissAlert(index);
  };

  public render() {
    const { alerts } = this.props;

    return (
      <div className="fixed-bottom mb-4 mr-4 d-flex flex-column align-items-end system-alerts">
        {alerts.map((alert, index) => (
          <Alert key={`alert-${index}`} alert={alert} onDismiss={() => this.onDismiss(index)} />
        ))}
      </div>
    );
  }
}

export default Alerts;
