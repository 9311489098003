import React from 'react';
import { Col, Row } from 'reactstrap';
import { formatRecommendationData } from '../../utilities';

interface RenderDetailedRowsProps {
  rowDetails: any[];
  node: any;
}

const RenderDetailedRows: React.FC<RenderDetailedRowsProps> = ({ rowDetails, node }) => (
  <>
    {rowDetails.map(row => (
      <Row key={row.key} className="mb-2">
        <Col xs={3} className="text-right font-weight-bold">
          {row.label}
        </Col>
        <Col xs={9}>{formatRecommendationData(row, node)}</Col>
      </Row>
    ))}
  </>
);

export default RenderDetailedRows;
