/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type LabelFormModalQueryVariables = {
    id: string;
};
export type LabelFormModalQueryResponse = {
    readonly getLabel: {
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly description: string | null;
            readonly parentId: string | null;
        };
    };
};
export type LabelFormModalQuery = {
    readonly response: LabelFormModalQueryResponse;
    readonly variables: LabelFormModalQueryVariables;
};



/*
query LabelFormModalQuery(
  $id: ID!
) {
  getLabel(id: $id) {
    node {
      id
      name
      description
      parentId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LabelEdge",
    "kind": "LinkedField",
    "name": "getLabel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Label",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "parentId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelFormModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelFormModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LabelFormModalQuery",
    "operationKind": "query",
    "text": "query LabelFormModalQuery(\n  $id: ID!\n) {\n  getLabel(id: $id) {\n    node {\n      id\n      name\n      description\n      parentId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ca8f817ecb6d51f07e4e3a820df30386';
export default node;
