import React from 'react';
// @ts-ignore
import { AppSwitch } from '@coreui/react';
import { BaseInputProps } from './Base';

interface SwitchProps extends BaseInputProps {
  labelOn?: string;
  labelOff?: string;
}

const Switch: React.FunctionComponent<SwitchProps> = ({
  name,
  label,
  labelOn,
  labelOff,
  values,
  onChange,
  type,
  ...otherProps
}) => {
  const value = values ? values.getIn(name.split('.')) : undefined;
  const isChecked = typeof value === 'string' ? value === 'true' : value;

  return (
    <AppSwitch
      name={name}
      type="checkbox"
      onClick={() =>
        onChange &&
        onChange({
          target: {
            id: name,
            value: !isChecked,
          },
        } as any)}
      checked={isChecked}
      variant="pill"
      color="primary"
      label={label}
      dataOn={labelOn}
      dataOff={labelOff}
      {...otherProps}
    />
  );
};

export default Switch;
