/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LabelsTreeDataWrapper_selectedLabels = ReadonlyArray<{
    readonly id: string;
    readonly " $refType": "LabelsTreeDataWrapper_selectedLabels";
}>;
export type LabelsTreeDataWrapper_selectedLabels$data = LabelsTreeDataWrapper_selectedLabels;
export type LabelsTreeDataWrapper_selectedLabels$key = ReadonlyArray<{
    readonly " $data"?: LabelsTreeDataWrapper_selectedLabels$data;
    readonly " $fragmentRefs": FragmentRefs<"LabelsTreeDataWrapper_selectedLabels">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LabelsTreeDataWrapper_selectedLabels",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Label"
};
(node as any).hash = 'e8bc6465c2decacec74daa9275fd3f88';
export default node;
