import { RecommendationsTypeMapping } from '../../constants';

export default Object.values(RecommendationsTypeMapping).reduce((navList: any[], typeDetails) => {
  navList.push({
    name: typeDetails.pluralName,
    url: `/${typeDetails.baseUrl}/list`,
    icon: `fa fa-${typeDetails.icon}`,
  });

  return navList;
}, []);
