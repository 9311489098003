import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import env from '../env';
import * as customMiddleware from './middleware';
import reducers from './reducers';

const getMiddleware = () => {
  const middleware = [thunk, customMiddleware.promiseAction];

  let composeEnhancers = compose;

  if (env.isDevelopment()) {
    middleware.push(logger as any);

    if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  return composeEnhancers(applyMiddleware(...middleware));
};

const createComposedStore = () => {
  return createStore(reducers, getMiddleware());
};

const store = createComposedStore();

export default store;
