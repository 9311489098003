import React, { Fragment } from 'react';

interface FooterProps {}

const Footer: React.FunctionComponent<FooterProps> = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Fragment>
      <span>&copy; {currentYear} Just Pick Something. All rights reserved.</span>
      <span className="ml-auto">{`v${process.env.REACT_APP_VERSION}`}</span>
    </Fragment>
  );
};

export default Footer;
