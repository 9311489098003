import { Loadable } from '../../components';

const PlaylistsList = Loadable(() => import('./list'));
const PlaylistForm = Loadable(() => import('./form'));
const PlaylistView = Loadable(() => import('./view'));

const routes = [
  { path: '/playlists', exact: true, name: 'Playlists', component: PlaylistsList },
  { path: '/playlists/list', exact: true, name: 'List', component: PlaylistsList },
  {
    path: '/playlists/create',
    exact: true,
    name: 'Create playlist',
    component: PlaylistForm,
  },
  { path: '/playlists/:id', exact: true, name: 'Details', component: PlaylistView },
  {
    path: '/playlists/:id/edit',
    exact: true,
    name: 'Edit playlist',
    component: PlaylistForm,
  },
];

export default routes;
