import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import env from './env';
import { UserGroups } from './types';
import { Spinner } from './components';
import { Error403 } from './errorPages';
import routes from './routes';
import Layout from './layout';

type ApplicationWrapperProps = RouteComponentProps;

interface ApplicationWrapperState {
  isLoading: boolean;
  isAuthorized?: boolean;
}

export default class ApplicationWrapper extends React.PureComponent<
  ApplicationWrapperProps,
  ApplicationWrapperState
> {
  constructor(props: ApplicationWrapperProps) {
    super(props);

    this.state = {
      isLoading: true,
      isAuthorized: undefined,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.setIsAuthorized();
  }

  private setIsAuthorized = async (): Promise<void> => {
    const expectedGroup = env.isAdmin() ? UserGroups.ADMIN : UserGroups.BUSINESS;
    let groups = [];
    let user;

    try {
      user = await Auth.currentAuthenticatedUser();
      groups = user?.signInUserSession.accessToken.payload['cognito:groups'] ?? [];
    } catch (e) {
      console.error(e);
    }

    this.setState({
      isLoading: false,
      isAuthorized: groups.includes(expectedGroup),
    });
  };

  public render(): React.ReactNode {
    const { isLoading, isAuthorized } = this.state;
    let content = <Error403 />;

    if (isLoading) {
      content = <Spinner />;
    } else if (isAuthorized) {
      content = <Layout routes={routes} {...this.props} />;
    }

    return content;
  }
}
