import React, { FunctionComponent } from 'react';
import { Button } from 'reactstrap';
import { MediaListItem, isMediaToUpload } from '../../types';

interface GalleryMediaProps {
  media: MediaListItem;
  onClick(): void;
  disabled?: boolean;
}

const GalleryMedia: FunctionComponent<GalleryMediaProps> = ({
  media,
  onClick,
  disabled,
}) => {
  const source = isMediaToUpload(media) ? URL.createObjectURL(media.file) : media.url;

  return (
    <Button
      className="media-gallery__item"
      onClick={onClick}
      disabled={disabled}
    >
      <img src={source} alt="" />
    </Button>
  );
}

export default GalleryMedia;