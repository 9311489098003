/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type CreateLabelInput = {
    bucketType?: string | null;
    clientMutationId?: string | null;
    description?: string | null;
    name: string;
    parentId?: string | null;
};
export type LabelFormModalCreateMutationVariables = {
    input: CreateLabelInput;
};
export type LabelFormModalCreateMutationResponse = {
    readonly createLabel: {
        readonly node: {
            readonly id: string;
            readonly name: string;
            readonly description: string | null;
        } | null;
    };
};
export type LabelFormModalCreateMutation = {
    readonly response: LabelFormModalCreateMutationResponse;
    readonly variables: LabelFormModalCreateMutationVariables;
};



/*
mutation LabelFormModalCreateMutation(
  $input: CreateLabelInput!
) {
  createLabel(input: $input) {
    node {
      id
      name
      description
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateLabelInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLabelPayload",
    "kind": "LinkedField",
    "name": "createLabel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Label",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelFormModalCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelFormModalCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LabelFormModalCreateMutation",
    "operationKind": "mutation",
    "text": "mutation LabelFormModalCreateMutation(\n  $input: CreateLabelInput!\n) {\n  createLabel(input: $input) {\n    node {\n      id\n      name\n      description\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f59c82ebde7ed61c9c4086d6bc5fa860';
export default node;
