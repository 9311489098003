import React, { PureComponent, ReactNode } from 'react';
import { Table as RSTable } from 'reactstrap';
import { List, Map } from 'immutable';
import Spinner from '../spinner';

interface Column {
  key: string;
  label: React.ReactNode;
}

type Data = Map<any, any>;

interface TableProps {
  columns: Column[];
  className?: string;
  isLoading?: boolean;
  data?: List<Data>;
  onRenderRow?: () => ReactNode;
  getRowKey?: (node: Data) => string;
}

class Table extends PureComponent<TableProps> {
  private renderDataRow = (dataRow: Data): ReactNode => {
    const { columns, getRowKey } = this.props;

    return (
      <tr key={getRowKey ? getRowKey(dataRow) : dataRow.get('key', '')}>
        {columns.map((column: Column) => (
          <td key={column.key}>{dataRow.get(column.key)}</td>
        ))}
      </tr>
    );
  };

  private renderContent(): ReactNode {
    const { isLoading, data, columns, onRenderRow } = this.props;
    let content: ReactNode;

    if (isLoading) {
      content = (
        <tr>
          <td colSpan={columns.length}>
            <Spinner />
          </td>
        </tr>
      );
    } else if (!data || data.isEmpty()) {
      content = (
        <tr>
          <td colSpan={columns.length}>
            <p>No data</p>
          </td>
        </tr>
      );
    } else {
      const renderFunction = onRenderRow || this.renderDataRow;
      content = data.map(renderFunction);
    }

    return <tbody>{content}</tbody>;
  }

  private renderHeader(): ReactNode {
    const { columns } = this.props;

    return (
      <thead className="thead-light">
        <tr>
          {columns.map((column: Column) => (
            <th key={column.key}>{column.label}</th>
          ))}
        </tr>
      </thead>
    );
  }

  public render(): ReactNode {
    return (
      <RSTable className="table-outline mb-0 d-table" hover responsive>
        {this.renderHeader()}
        {this.renderContent()}
      </RSTable>
    );
  }
}

export default Table;
