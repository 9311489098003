/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkPlatform = "ANDROID" | "IOS" | "WEB" | "%future added value";
export type LinkProvider = "AMAZON" | "APPLE" | "APP_STORE" | "FACEBOOK" | "GOOGLE" | "GOOGLE_PLAY" | "HULU" | "NETFLIX" | "STEAM" | "VUDU" | "WEBSITE" | "YOUTUBE" | "%future added value";
export type LinksList_links = ReadonlyArray<{
    readonly text: string | null;
    readonly url: string;
    readonly platform: LinkPlatform | null;
    readonly provider: LinkProvider;
    readonly cost: number | null;
    readonly " $refType": "LinksList_links";
}>;
export type LinksList_links$data = LinksList_links;
export type LinksList_links$key = ReadonlyArray<{
    readonly " $data"?: LinksList_links$data;
    readonly " $fragmentRefs": FragmentRefs<"LinksList_links">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LinksList_links",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "provider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cost",
      "storageKey": null
    }
  ],
  "type": "Link"
};
(node as any).hash = '502dec403cebef2fe06cad9cf716b359';
export default node;
