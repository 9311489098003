import React, { PureComponent } from 'react';
import { Button, Alert, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { createFragmentContainer } from 'react-relay';
import uuid from 'uuid';
import graphql from 'babel-plugin-relay/macro';
import { Media, MediaUpload, MediaProvider, MediaType, isUploadedMedia, MediaList, isMediaToUpload } from '../../types';
import MediaDeleteModal from './MediaDeleteModal';
import MediaFormModal from './MediaFormModal';
import { Storage } from 'aws-amplify';
import env from '../../env';
import GalleryMedia from './GalleryMedia';

type ModalType = 'create' | 'delete';

interface GalleryProps {
  media: MediaList;
  canAdd?: boolean;
  canDelete?: boolean;
  onUpdate?(mediaList: any[]): void;
  onDelete?(mediaToDelete: Media): void;

  parentId?: any;
  saveMutation?: any;
  deleteMutation?: any;
}

interface GalleryState {
  modalMedia: any;
  modalMediaIndex: number;
  openModal?: ModalType;
  hasError?: boolean;
}

export class Gallery extends PureComponent<GalleryProps, GalleryState> {
  constructor(props: GalleryProps) {
    super(props);

    this.state = {
      openModal: undefined,
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    };
  }

  private handleCloseModal = (): void => {
    this.setState({
      openModal: undefined,
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    });
  };

  private handleMediaClick = (media: any, index: number): void => {
    const { canDelete } = this.props;

    if (canDelete) {
      this.setState({
        openModal: 'delete',
        modalMedia: media,
        modalMediaIndex: index,
        hasError: false,
      });
    }
  };

  private handleCreateMedia = (): void => {
    this.setState({
      openModal: 'create',
      modalMedia: {},
      modalMediaIndex: -1,
      hasError: false,
    });
  };

  private handleMediaUpdate = async (mediaValues: any): Promise<void> => {
    const { media, onUpdate } = this.props;
    const { file, url } = mediaValues;
    const mediaList = Object.assign([], media) as MediaList;

    if (file) {
      mediaList.push({
        file,
        provider: MediaProvider.JPS,
        type: MediaType.IMAGE,
      })
    } else if (url) {
      mediaList.push({
        url,
      });
    }

    onUpdate?.(mediaList);

    this.handleCloseModal();
  }

  private handleMediaDelete = async (mediaListIndex: number): Promise<void> => {
    const { media, onUpdate, onDelete } = this.props;
    const mediaList = Object.assign([], media) as MediaList;
    const mediaDetails = mediaList[mediaListIndex];

    if (isUploadedMedia(mediaDetails) && mediaDetails.provider === MediaProvider.JPS) {
      onDelete?.(mediaDetails);
    }

    mediaList.splice(mediaListIndex, 1);

    onUpdate?.(mediaList);

    this.handleCloseModal();
  }

  private renderMedia(): React.ReactNode {
    const { media, canDelete } = this.props;

    let content: any = (
      <Alert color="secondary" className="mt-3 text-center w-100">
        No media found
      </Alert>
    );

    if (media && media.length > 0) {
      content = media.map((singleMedia, index) => {
        const key = isMediaToUpload(singleMedia) ? singleMedia.file.name : singleMedia.url;

        return (
          <GalleryMedia
            key={key}
            media={singleMedia}
            onClick={() => this.handleMediaClick(singleMedia, index)}
            disabled={!canDelete}
          />
        )
      });
    }

    return content;
  }

  public render(): React.ReactNode {
    const { canAdd, canDelete } = this.props;
    const { openModal, modalMedia, modalMediaIndex, hasError } = this.state;

    return (
      <>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-image" />
                Media
                {canAdd && (
                  <div className="card-header-actions">
                    <Button
                      className="card-header-action btn"
                      color="link"
                      onClick={this.handleCreateMedia}
                    >
                      <i className="fa fa-plus" />
                      &nbsp;Add new
                    </Button>
                  </div>
                )}
              </CardHeader>
              <CardBody>
                <div className="media-gallery">{this.renderMedia()}</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {canDelete && (
          <MediaDeleteModal
            isOpen={openModal === 'delete'}
            toggle={this.handleCloseModal}
            onDelete={() => this.handleMediaDelete(modalMediaIndex)}
            media={modalMedia}
            hasError={hasError}
          />
        )}
        {canAdd && (
          <MediaFormModal
            isOpen={openModal === 'create'}
            onToggle={this.handleCloseModal}
            onSave={this.handleMediaUpdate}
            hasError={hasError}
          />
        )}
      </>
    );
  }
}

export const RelayGallery = createFragmentContainer(Gallery, {
  media: graphql`
    fragment Gallery_media on Media @relay(plural: true) {
      url
      type
    }
  `,
});
