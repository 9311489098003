import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { fromJS, Map } from 'immutable';
import { Treebeard, decorators } from 'react-treebeard';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { BaseInputProps } from './Base';

function listToTree(list: Node[]) {
  let map: { [key: string]: number } = {};
  let node: Node;
  let roots: Node[] = [];
  let i: number;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent) {
      if (!list[map[node.parent]].children) {
        list[map[node.parent]].children = [];
      }

      list[map[node.parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }

  return roots;
}

interface Node {
  id: string;
  name: string;
  children: Node[];
  parent?: string;
  toggled?: boolean;
  active?: boolean;
  selected?: boolean;
}

type Data = Map<string, Node>;

interface TreeProps extends BaseInputProps {
  data: Data;
  formatData?: boolean;
}

interface TreeState {
  cursor?: Node;
  mappedData?: Data;
  rawData?: Data;
}

export default class Tree extends PureComponent<TreeProps, TreeState> {
  public state: TreeState = {
    mappedData: Map<string, any>(),
    cursor: undefined,
    rawData: undefined
  };

  public static getDerivedStateFromProps(props: TreeProps, state: TreeState): TreeState {
    const { data, formatData } = props;

    if (data && (!state.rawData || !data.equals(state.rawData))) {
      state.rawData = data;
      state.mappedData = formatData ? Tree.createMappedData(data) : data;
    }

    return state;
  }

  public static createMappedData(data: Data): Data {
    let mapping = fromJS({
      establishment: {
        id: 'establishment',
        name: 'Establishment',
        children: []
      },
      ambiance: {
        id: 'ambiance',
        name: 'Ambiance',
        children: []
      },
      food: {
        id: 'food',
        name: 'Food',
        children: []
      },
      drink: {
        id: 'drink',
        name: 'Drink',
        children: []
      }
    });

    const treeData = fromJS(listToTree(data.toList().toJS()));

    treeData.forEach((item: Map<string, any>) => {
      mapping = mapping.updateIn([item.get('type'), 'children'], (children: Map<string, any>[]) =>
        children.push(item)
      );
    });

    return mapping.toList().toJS();
  }

  public render(): React.ReactNode {
    const { name, label, errors } = this.props;
    const { mappedData } = this.state;

    const error = errors ? errors.getIn(name.split('.')) : undefined;
    const hasError = !!error;
    const classes = classNames('tree-input', {
      'is-invalid': hasError
    });

    return (
      <FormGroup className={classes}>
        <Label>{label}</Label>
        <Treebeard
          data={mappedData}
          onToggle={this.onToggle}
          decorators={this.renderDecorators()}
        />
        {hasError && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>
    );
  }

  private onToggle = (node: Node, toggled: boolean): void => {
    const { cursor: currentCursor } = this.state;

    node.active = true;

    if (currentCursor) {
      currentCursor.active = false;
    }

    if (node.children) {
      node.toggled = toggled;
    }

    this.setState({ cursor: node });
  };

  private onSelect = (node: Node, selected: boolean): void => {
    node.selected = selected;

    this.setState({ cursor: node });
  };

  private renderCheckbox = (node: Node): React.ReactNode => {
    return (
      <input
        type="checkbox"
        checked={!!node.selected}
        onClick={() => this.onSelect(node, !!!node.selected)}
      />
    );
  };

  private renderNode = (props: { node: Node; style?: React.CSSProperties }): React.ReactNode => {
    return (
      <div style={props.style}>
        {this.renderCheckbox(props.node)}
        {props.node.name}
      </div>
    );
  };

  private renderDecorators(): object {
    return {
      ...decorators,
      Header: this.renderNode
    };
  }
}
