import Divider from '../../divider';
import Base from './Base';
import Address from './Address';
import Dropdown from './Dropdown';
import WeeklyHours from './WeeklyHours';
import Switch from './Switch';
import TagsInput from './Tags';
import TreeInput from './Tree';
import StateSelector from './StateSelector';

interface InputMappingSignature {
  [key: string]: React.ReactNode;
}

const inputMapping: InputMappingSignature = {
  div: Divider,
  base: Base,
  address: Address,
  dropdown: Dropdown,
  weeklyHours: WeeklyHours,
  switch: Switch,
  tags: TagsInput,
  tree: TreeInput,
};

export { Base, Address, Dropdown, WeeklyHours, Switch, TagsInput, TreeInput, StateSelector };

export default inputMapping;
