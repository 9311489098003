import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from './utilities';

const PageViewLogger: React.FunctionComponent<RouteComponentProps> = ({ history, children }) => {
  history.listen(() => {
    analytics.page();
  });

  return children as React.ReactElement;
};

export default connect(PageViewLogger);
