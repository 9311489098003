import InitialConfig from 'react-awesome-query-builder/lib/config/antd';

export const formConditionsFieldConfig = {
  ...InitialConfig,
  fields: {
    labels: {
      label: 'Labels',
      type: 'treemultiselect',
      fieldSettings: {
        listValues: [],
        treeExpandAll: true,
        treeSelectOnlyLeafs: false,
      },
    },
    distance: {
      label: 'Distance',
      type: 'number',
      fieldSettings: {
        min: 0,
        max: 20,
      },
    },
    recommendationsType: {
      label: 'Recommendation types',
      type: 'multiselect',
      fieldSettings: {
        listValues: [
          { value: 'ACTIVITY', title: 'Activities' },
          { value: 'BOOK', title: 'Books' },
          { value: 'ESTABLISHMENT', title: 'Establishments' },
          { value: 'EVENT', title: 'Events' },
          { value: 'GAME', title: 'Games' },
          { value: 'TV_MOVIE', title: 'TV & Movies' },
        ],
      },
    },
  },
} as any;
