import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import Base, { BaseInputProps } from './Base';

const WeeklyHoursInputs: React.FunctionComponent<BaseInputProps> = ({ name, ...otherProps }) => {
  const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <div>
      {DAYS.map(day => (
        <Row key={day}>
          <Col xs="12" sm="2" className="d-sm-flex align-items-sm-center">
            <Label>{day}</Label>
          </Col>
          <Col xs="6" sm="5">
            <Base {...otherProps} name={`${name}.${day.toLowerCase()}.start`} type="time" />
          </Col>
          <Col xs="6" sm="5">
            <Base {...otherProps} name={`${name}.${day.toLowerCase()}.end`} type="time" />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default WeeklyHoursInputs;
