import React from 'react';
import { List, Map } from 'immutable';
import classNames from 'classnames';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { BaseInputProps } from './Base';

interface Suggestion {
  id: string;
  type: string;
}

interface TagsProps extends BaseInputProps {
  tags: List<Map<string, string>>;
  typeFilter: string;
  asArray?: boolean;
}

const Tags: React.FunctionComponent<TagsProps> = ({
  values,
  name,
  label,
  errors,
  tags,
  typeFilter,
  onChange,
  asArray
}) => {
  function getValue(): List<string> {
    const value = values ? values.getIn(name.split('.')) : '';
    const tags = value ? (asArray ? value : value.split(',')) : [];

    return List(tags);
  }

  function onAdd(tag: Tag): void {
    let tags = getValue();

    tags = tags.push(tag.id ? tag.id.toString() : tag.name);

    if (onChange) {
      onChange({
        target: {
          id: name,
          value: asArray ? tags : tags.join(',')
        }
      } as any);
    }
  }

  function onRemove(index: number): void {
    let tags = getValue().splice(index, 1);

    if (onChange) {
      onChange({
        target: {
          id: name,
          value: asArray ? tags : tags.join(',')
        }
      } as any);
    }
  }

  const error = errors ? errors.getIn(name.split('.')) : undefined;
  const hasError = !!error;
  const classes = classNames('tags-input', {
    'is-invalid': hasError
  });

  const value = getValue();
  const currentTags = value
    .map((tag: any) => ({
      id: tag,
      name: tags.getIn([tag, 'name'])
    }))
    .toArray();

  let suggestions = tags
    .map((tag: any) => ({
      id: tag.get('id'),
      name: tag.get('name'),
      type: tag.get('type')
    }))
    .filter((suggestion: any) => value.indexOf(suggestion.id) < 0);

  if (typeFilter) {
    suggestions = suggestions.filter((suggestion: any) => suggestion.type === typeFilter);
  }

  return (
    <FormGroup className={classes}>
      <Label>{label}</Label>
      <ReactTags
        tags={currentTags}
        handleDelete={onRemove}
        handleAddition={onAdd}
        autoresize={false}
        suggestions={suggestions.toArray()}
      />
      {hasError && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};

export default Tags;
