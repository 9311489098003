import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { Row, Col, Card, CardHeader, CardBody, Button, Form as RsForm } from 'reactstrap';
import Divider from '../divider';
import { FormConfig, FormSection, FormField } from './types';
import inputMapping from './inputs';

interface FormProps {
  config: FormConfig;
  values: Map<string, string>;
  errors: Map<string, string>;
  onFieldChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onCancel?: () => void;
  parent?: any;
  hideButtons?: boolean;
  disabled?: boolean;
  noContainer?: boolean;
}

export default class Form extends PureComponent<FormProps> {
  private renderField = (field: FormField): React.ReactNode => {
    const { values, errors, disabled, onFieldChange, parent } = this.props;
    const fieldName = field.name || '';

    const type = field.type || 'text';
    const sizes = field.sizes || { xs: '12' };

    const FieldType: any = inputMapping[type] || inputMapping.base;

    return (
      <Row key={fieldName}>
        <Col {...sizes}>
          <FieldType
            disabled={disabled}
            {...field}
            type={type}
            onChange={onFieldChange}
            values={values}
            errors={errors}
            parent={parent}
          />
        </Col>
      </Row>
    );
  };

  private renderSection = (section: FormSection): React.ReactNode => {
    const { noContainer } = this.props;
    const { fields } = section;
    const sizes = section.sizes || { xs: '12' };
    const renderedFields = fields.map(this.renderField as any);
    let renderedSection;

    if (!noContainer) {
      renderedSection = (
        <Col key={section.name} {...sizes}>
          <Card>
            <CardHeader>{section.name}</CardHeader>
            <CardBody>{renderedFields}</CardBody>
          </Card>
        </Col>
      );
    } else {
      renderedSection = (
        <Col key="section" xs="12">
          {renderedFields}
        </Col>
      );
    }

    return renderedSection;
  };

  private renderButtons(): React.ReactNode | null {
    const { disabled, onCancel, onSubmit, hideButtons } = this.props;
    let buttons = null;

    if (!hideButtons) {
      buttons = (
        <Row className="mb-4">
          <Col xs="12">
            <Divider />
          </Col>
          <Col xs="12" sm="6">
            {onCancel && (
              <Button color="link" onClick={onCancel} disabled={disabled} block>
                Cancel
              </Button>
            )}
          </Col>
          <Col xs="12" sm="6">
            <Button color="primary" onClick={onSubmit} disabled={disabled} block>
              Save
            </Button>
          </Col>
        </Row>
      );
    }

    return buttons;
  }

  public render(): React.ReactNode {
    const { config, onSubmit } = this.props;

    return (
      <RsForm onSubmit={onSubmit}>
        <Row>{config.map(this.renderSection as any)}</Row>
        {this.renderButtons()}
      </RsForm>
    );
  }
}
