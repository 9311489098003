import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import Input from './Input';
import { FormFieldProps } from './types';
import { useOMDbLookup } from './hooks';

const OMDbLookup: React.FC<FormFieldProps> = ({ values, field, setFieldValue }) => {
  const { name, label, extraProps } = field;
  const [loading, details] = useOMDbLookup({
    title: values?.[name]?.title,
    imdbId: values?.[name]?.imdbId,
  });

  useEffect(() => {
    if (details) {
      if (details.Error) {
        console.error(details.Error);
      } else {
        const rating = details.Rated.replace('-', '_').replace('N/A', 'UNKNOWN');

        setFieldValue?.('imdbId', details.imdbID);
        setFieldValue?.('name', details.Title);
        setFieldValue?.('description', details.Plot);
        setFieldValue?.('director', details.Director);
        setFieldValue?.('writer', details.Writer);
        setFieldValue?.('actors', details.Actors);
        setFieldValue?.('genre', details.Genre);

        setFieldValue?.('media.url', details.Poster);

        if (extraProps?.type === 'movie') {
          setFieldValue?.('movieRating', rating);
          setFieldValue?.('year', details.Year);
        } else if (extraProps?.type === 'tv') {
          const [startYear, endYear] = details.Year.split('–');
          const totalSeasons = details.totalSeasons;
          const totalYears = endYear ? endYear - startYear : new Date().getFullYear() - startYear;
          const numOfSeasonsPerYear = Math.round(totalYears / totalSeasons);

          setFieldValue?.('tvRating', rating);
          setFieldValue?.('startYear', startYear);
          setFieldValue?.('endYear', endYear);
          setFieldValue?.('numOfSeasonsPerYear', numOfSeasonsPerYear);
        }
      }
    }
  }, [details]);

  return (
    <Row className="mb-2 pt-1 border border-primary">
      <Col md={12}>
        <h3>{label ?? 'Lookup by OMDb'}</h3>
      </Col>
      <Col>
        <Input
          field={{
            name: `${name}.title`,
            label: 'Title',
          }}
        />
      </Col>
      <Col>
        <Input
          field={{
            name: `${name}.imdbId`,
            label: 'IMDb ID',
          }}
        />
      </Col>
    </Row>
  );
};

export default OMDbLookup;
