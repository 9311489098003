import React from 'react';
import { Field } from 'formik';
import { FormFieldProps } from './types';

const HiddenInput: React.FC<FormFieldProps> = ({ field }) => {
  const fieldId = field.id ?? field.name;

  return <Field id={fieldId} type="hidden" name={field.name} />;
};

export default HiddenInput;
