import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface NestedRelationshipDeleteModalProps {
  toggle: () => void;
  onDelete: () => void;
  header?: string;
  content: React.ReactNode;
  isOpen?: boolean;
  className?: string;
}

const NestedRelationshipDeleteModal: React.FunctionComponent<NestedRelationshipDeleteModalProps> = ({
  isOpen,
  toggle,
  className,
  onDelete,
  header = 'Delete?',
  content,
}) => (
  <Modal className={className} isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>{header}</ModalHeader>
    <ModalBody>{content}</ModalBody>
    <ModalFooter>
      <Button
        color="danger"
        onClick={() => {
          onDelete();
          toggle();
        }}
      >
        Delete
      </Button>
      <Button color="secondary" onClick={toggle}>
        Cancel
      </Button>
    </ModalFooter>
  </Modal>
);

export default NestedRelationshipDeleteModal;
