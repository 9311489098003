/* eslint-disable no-restricted-globals */

export default function formatCurrency(cost: string | number): string {
  const costAsNumber = Number(cost);
  let currencyValue = 'Free';

  if (!isNaN(costAsNumber) && costAsNumber > 0) {
    currencyValue = `$${costAsNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }

  return currencyValue;
}
