import { ReactNode } from 'react';
import { Alert, AlertLevel } from '../types';

export interface ISystemAlert {
  getLevel(): AlertLevel;
  getHeader(): string;
  getMessage(): ReactNode;
  isDismissable(): boolean;
  getDismissTime(): number | undefined;
}

export default class SystemAlert implements ISystemAlert {
  private alert: Alert;

  constructor(props: Alert) {
    this.alert = props;
  }

  public getLevel(): AlertLevel {
    return this.alert.level;
  }

  public getHeader(): string {
    let header: string;

    switch (this.getLevel()) {
      case AlertLevel.SUCCESS:
        header = 'Success';
        break;
      case AlertLevel.INFO:
        header = 'Info';
        break;
      case AlertLevel.DANGER:
      case AlertLevel.ERROR:
        header = 'Error';
        break;
      case AlertLevel.WARNING:
        header = 'Warning';
        break;
      default:
        header = 'Attention';
        break;
    }

    return header;
  }

  public getMessage(): ReactNode {
    return this.alert.message;
  }

  public isDismissable(): boolean {
    return this.alert.dismissable ?? true;
  }

  public getDismissTime(): number | undefined {
    return this.alert.dismissTime ?? 5000;
  }
}
