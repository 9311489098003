import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Routes = ({ routes }: { routes: any[] }) => (
  <Switch>
    {routes.map((route: any) => {
      if (!route.component) {
        console.warn('Unknown component', route);
      }

      return (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          render={routeProps => <route.component {...routeProps} />}
        />
      );
    })}
    <Redirect from="/" to="/dashboard" />
  </Switch>
);

export default Routes;
