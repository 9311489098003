type Address = {
  street1: string;
  street2?: string;
  city: string;
  state: string;
  country?: string;
  postalCode?: string;
};

type FormatAddressOptions = {
  showCountry?: boolean;
  showPostalCode?: boolean;
};

export default function formatAddress(
  { street1, street2, city, state, country, postalCode }: Address,
  options: FormatAddressOptions = {},
): string {
  const { showCountry, showPostalCode } = options;
  const parts = [];

  if (street1) {
    parts.push(street1);
  }

  if (street2) {
    parts.push(street2);
  }

  parts.push(city);
  parts.push(state);

  if (showCountry && country) {
    parts.push(country);
  }

  if (showPostalCode && postalCode) {
    parts.push(postalCode);
  }

  return parts.join(', ');
}
