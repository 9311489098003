import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary } from './errorPages';
import store from './store';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

registerServiceWorker();
unregister();
