import { ReactNode } from 'react';

export enum AlertLevel {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  ERROR = 'danger',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface Alert {
  level: AlertLevel;
  message: ReactNode;
  dismissable?: boolean;
  dismissTime?: number;
}
