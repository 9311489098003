import { Loadable } from '../../components';
import { RecommendationsTypeMapping } from '../../constants';

const RecommendationsList = Loadable(() => import('./list'));
const RecommendationsForm = Loadable(() => import('./form'));
const RecommendationsView = Loadable(() => import('./view'));

const recommendationTypes = Object.values(RecommendationsTypeMapping)
  .map(typeDetails => typeDetails.baseUrl)
  .join('|');

export default [
  {
    path: `/:recommendationType(${recommendationTypes})`,
    exact: true,
    name: 'Recommendations',
    component: RecommendationsList,
  },
  {
    path: `/:recommendationType(${recommendationTypes})/list`,
    exact: true,
    name: 'List',
    component: RecommendationsList,
  },
  {
    path: `/:recommendationType(${recommendationTypes})/create`,
    exact: true,
    name: 'Create',
    component: RecommendationsForm,
  },
  {
    path: `/:recommendationType(${recommendationTypes})/:id`,
    exact: true,
    name: 'Details',
    component: RecommendationsView,
  },
  {
    path: `/:recommendationType(${recommendationTypes})/:id/edit`,
    exact: true,
    name: 'Edit',
    component: RecommendationsForm,
  },
];
