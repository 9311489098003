export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum MediaProvider {
  JPS = 'jps',
}

export interface MediaDetails {
  name?: string;
  description?: string;
  provider?: MediaProvider | string;
  type?: MediaType;
}

export interface Media extends MediaDetails {
  url: string;
}

export interface MediaUpload extends MediaDetails {
  file: File;
}

export type MediaListItem = Media | MediaUpload;
export type MediaList = MediaListItem[];

export function isUploadedMedia(media: Media | MediaUpload): media is Media {
  return Object.prototype.hasOwnProperty.call(media, "url");
}

export function isMediaToUpload(media: Media | MediaUpload): media is MediaUpload {
  return Object.prototype.hasOwnProperty.call(media, "file");
}