import React from 'react';
import Spinner from '../spinner';

interface PageLoadingProps {
  retry: () => void;
  error?: string;
}

const PageLoading: React.FunctionComponent<PageLoadingProps> = ({ error, retry }) => {
  let rendered = <Spinner />;

  if (error) {
    console.error(error);

    rendered = (
      <div>
        Oops, something didn't load right.
        <button onClick={retry}>Try again</button>
      </div>
    );
  }

  return rendered;
};

export default PageLoading;
